import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationIcon,
  PencilAltIcon,
  QuestionMarkCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ReceiptTaxIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline";
import React, { Fragment, ReactNode, useRef } from "react";
import { XIcon } from "@heroicons/react/solid";
import clsx from "classnames";
import SupportModal from "components/SupportModal/SupportModal";

type Icon =
  | "success"
  | "exclamation"
  | "questionMark"
  | "crop"
  | "clock"
  | "exclamation-circle"
  | "exclamation-circle-gray"
  | "promotion"
  | "usd-circle-gray"
  | undefined;

interface IModalProps {
  title?: string;
  description?: string;
  children: ReactNode;
  isOpen: boolean;
  icon?: Icon;
  alignTitle?: "text-center" | "text-left" | "text-right" | "text-justify";
  onClose?: () => void;
  widthType?: "md" | "xl" | "sm" | "lg" | "scanner" | "xxs";
  showCloseBtn?: boolean;
  warningMessage?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  zIndex?: string;
  styleContainer?: string;
  position?: string;
  supportModal?: boolean;
}

const Modal = ({
  title,
  description,
  children,
  isOpen,
  icon,
  onClose = () => {},
  alignTitle = "text-center",
  widthType = "md",
  showCloseBtn = true,
  warningMessage,
  borderRadius = "rounded-md",
  width = "w-full",
  zIndex = "z-50",
  styleContainer = "bg-black/50 bg-opacity-80",
  position = "block",
  supportModal,
}: IModalProps) => {
  const completeRef = useRef(null);

  const Icon = ({ type }: { type: Icon }) => {
    switch (type) {
      case "exclamation": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <ExclamationIcon className="h-6 w-6 text-red-600" />
          </div>
        );
      }
      case "exclamation-circle": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
          </div>
        );
      }
      case "exclamation-circle-gray": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-slate-100">
            <ExclamationCircleIcon className="h-6 w-6 text-slate-400" />
          </div>
        );
      }
      case "success": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" />
          </div>
        );
      }
      case "questionMark": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
            <QuestionMarkCircleIcon className="h-6 w-6 text-gray-600" />
          </div>
        );
      }
      case "crop": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-400">
            <PencilAltIcon className="h-6 w-6 text-white" />
          </div>
        );
      }
      case "clock": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
            <ClockIcon className="h-6 w-6 text-grey-100" />
          </div>
        );
      }
      case "promotion": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
            <ReceiptTaxIcon className="h-6 w-6 text-slate-500" />
          </div>
        );
      }
      case "usd-circle-gray": {
        return (
          <div className="mb-3 s:mb-5 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-slate-100">
            <CurrencyDollarIcon className="h-6 w-6 text-slate-400" />
          </div>
        );
      }

      default:
        return null;
    }
  };

  return (
    // <Transition.Root show={isOpen} as={Fragment}>
    //   <Dialog
    //     as="div"
    //     static
    //     className="fixed flex flex-col items-center justify-center z-50 inset-0 w-full overflow-y-auto"
    //     open={isOpen}
    //     onClose={onClose}
    //     initialFocus={completeRef}
    //   >

    // <div
    //   className={clsx(
    //     "flex sm:items-end lg:items-center lg:px-4 lg:pb-20 md:items-end justify-center lg:min-h-screen w-full text-center sm:block"
    //   )}
    //   ref={completeRef}
    // >
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed flex flex-col items-center justify-center ${zIndex} inset-0  overflow-y-auto`}
        open={isOpen}
        onClose={onClose}
        initialFocus={completeRef}
      >
        <div
          className={clsx(
            `flex sm:items-end  lg:items-center lg:px-4 lg:pb-20 md:items-end justify-center lg:min-h-screen ${width} text-center sm:block`,
            borderRadius
          )}
          ref={completeRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`fixed inset-0 ${styleContainer} backdrop-filter backdrop-blur-sm`}
              aria-hidden="true"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={clsx(
                `z-40 inline-block align-bottom bg-white rounded-lg px-4 py-5 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6`,
                {
                  "sm:max-w-md": widthType === "md",
                  "sm:max-w-md md:max-w-lg xl:max-w-3xl": widthType === "xl",
                  "max-w-sm md:max-w-sm xl:max-w-md": widthType === "sm",
                  "max-w-sm md:max-w-[568px] xl:max-w-[568px]":
                    widthType === "lg",
                  "w-11/12 md:max-w-sm":
                    widthType === "scanner" || widthType === "xxs",
                }
              )}
            >
              <div>
                {showCloseBtn && (
                  <XIcon
                    className="absolute right-5 w-6 h-6 cursor-pointer opacity-50"
                    onClick={onClose}
                  />
                )}
                <Icon type={icon} />
                {title || description || warningMessage ? (
                  <div className={clsx(alignTitle)}>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    {description ? (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 whitespace-pre-line break-words">
                          {description}{" "}
                          {supportModal && <SupportModal type="text" />}
                        </p>
                      </div>
                    ) : null}
                    {warningMessage ? (
                      <p className="text-sm text-ruslan-red whitespace-pre-line break-words mt-2">
                        {warningMessage}
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className={`mt-5 sm:mt-6 ${position}`}>{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
