import Image from "next/image";
import { ReactNode } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

export const Footer = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <footer className="bg-black text-white">
      <div
        className={cx("max-w-xs xs:max-w-7xl m-auto divide-y divide-gray-800", {
          "pt-14": children,
        })}
      >
        {children}
        <div className="flex flex-col justify-between md:px-5 py-8 md:flex-row md:items-end md:mt-7">
          <span className="hidden mt-5 text-base text-gray-400 md:flex flex-col md:mt-0">
            <span>{t("allRightsReserved")}</span>
          </span>
          <ul className="flex gap-6 h-6 xl:mr-10">
            <li className="w-20 relative">
              <Image
                loading="eager"
                src="/img/client-landing/visa.svg"
                layout="fill"
                alt="image"
              />
            </li>
            <li className="w-6 relative">
              <a
                href="https://t.me/waylightme"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  loading="eager"
                  src="/img/social-links/telegram.svg"
                  layout="fill"
                  alt=""
                />
              </a>
            </li>
            <li className="w-6 relative">
              <a
                href="https://www.tiktok.com/@waylightme"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  loading="eager"
                  src="/img/social-links/tiktok.svg"
                  layout="fill"
                  alt=""
                />
              </a>
            </li>
            <li className="w-6 relative">
              <a
                href="https://youtube.com/channel/UCgJWe3BMMsPWHeD8uIHZXaA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  loading="eager"
                  src="/img/client-landing/icons/youtube.svg"
                  layout="fill"
                  alt=""
                />
              </a>
            </li>
            <li className="w-6 relative">
              <a
                href="https://www.facebook.com/waylightme/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  loading="eager"
                  src="/img/client-landing/icons/facebook.svg"
                  layout="fill"
                  alt=""
                />
              </a>
            </li>
            <li className="w-6 relative">
              <a
                href="https://instagram.com/waylightme"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  loading="eager"
                  src="/img/social-links/instagram.svg"
                  layout="fill"
                  alt=""
                />
              </a>
            </li>
            <li className="w-6 relative">
              <a
                href="https://www.linkedin.com/company/waylightme"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  loading="eager"
                  src="/img/client-landing/icons/linkedin.svg"
                  layout="fill"
                  alt=""
                />
              </a>
            </li>
          </ul>
          <span className="mt-5 text-sm text-gray-400 md:hidden md:mt-0 flex flex-col">
            <span>{t("allRightsReserved")}</span>
          </span>
        </div>
      </div>
    </footer>
  );
};
