import React, { Fragment, ReactNode, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import cx from "classnames";

const ExpertModal = ({
  isModalOpen,
  onModalClose,
  children,
  type = "none",
}: {
  isModalOpen: boolean;
  onModalClose: () => void;
  children: ReactNode;
  type?: "offer" | "profile" | "none";
}) => {
  const isOfferType = type === "offer";
  const focusElementRef = useRef(null);

  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        static
        open={isModalOpen}
        onClose={onModalClose}
        initialFocus={focusElementRef}
      >
        <div
          ref={focusElementRef}
          className={cx("h-full px-4 text-center", {
            "flex flex-col justify-center lg:justify-end items-center ":
              isOfferType,
            "flex flex-col justify-center items-center": !isOfferType,
          })}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-75 backdrop-filter backdrop-blur-sm"
              aria-hidden="true"
            />
          </Transition.Child>
          <div
            className="fixed top-3 right-4 p-2.5 z-10 bg-black/50 border border-white/[0.15] rounded-full cursor-pointer"
            onClick={onModalClose}
          >
            <XIcon className="w-6 h-6 text-white" />
          </div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="max-w-md w-full my-3 text-left align-middle transition-all transform shadow-xl  overflow-scroll scrollbar-progress-hidden">
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default ExpertModal;
